<template>
  <div id="app">
    
  </div>
</template>

<script>
import axios from "axios";


export default {
  data: function () {
    return {
      
    }
  }
}
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
